import React from 'react';

import { API } from '@stoplight/elements';
import '@stoplight/elements/styles.min.css';
import file from './openapi.json'

 const apiDescriptionDocument = file;


function App() {
    return (
        <div className="App">
            <API
                apiDescriptionDocument= {apiDescriptionDocument}
            />
        </div>
    );
}

export default App;
